<template>
  <div>
    <div id="main" class="site-main">

<div id="main-content" class="main-content">


<div id="page-title" class="page-title-block page-title-style-1 has-background-image" style="background-image: url(/assets/backgrounds/bank-soc-putevok/17.jpg);"><div class="container"><div class="page-title-title"><h1 style="">  Туризм в интересах старшего поколения</h1></div></div></div>




<div class="block-content">
	<div class="container">
		<div class="panel row">

			<div class="panel-center col-xs-12">
				<article id="post-11550" class="post-11550 page type-page status-publish hentry">


					<div class="entry-content post-content">
												<div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-12"><div class="vc_column-inner "><div class="wpb_wrapper"><div class="fullwidth-block clearfix styled-marker-top" style="background-color: #ffffff;background-image: url(/assets/backgrounds/bank-soc-putevok/bsp.jpg);background-repeat: no-repeat; background-size: cover;background-position: center top;"><div class="container"><div class="clearboth"></div><div class="sc-divider" style="margin-bottom: 100px;"></div><div class="centered-box"><div class="sc-icon sc-icon-size-big  sc-icon-shape-square sc-simple-icon" style=""><span class="sc-icon-half-1" style="color: #ffffff;"><span class="back-angle">&#xe768;</span></span><span class="sc-icon-half-2" style="color: #ffffff;"><span class="back-angle">&#xe768;</span></span></div></div>
	<div class="wpb_text_column wpb_content_element " >
		<div class="wpb_wrapper">
			<h1 style="text-align: center;"><span style="color: #ffffff;">Банк социальных путевок</span></h1>
<div class="styled-subtitle" style="text-align: center;">
<span style="color: #ffffff; font-size: 20px;">Создание экономически и инфраструктурно доступной туристской среды, а также условий для массовых путешествий лиц старшего поколения в туристских целях.</p>
<p>Культурно-познавательные, оздоровительные и другие программы, адаптированные под преклонный возраст.<br />
Организация занятости физически активных граждан пожилого возраста в сфере культуры и туризма.<br />
Создание стандартов качества туробслуживания для граждан пожилого возраста.</p>
<p>Организация межрегионального обмена туристскими группами пожилых граждан.</p></span></div>

		</div>
	</div>
<div class="centered-box"><a class="sc-button "  href="http://xn--80abelycrjchuom4b.xn--p1ai/" target="_self" style="background-color: #b5cb5c;">Сайт проекта</a></div><div class="clearboth"></div><div class="sc-divider" style="margin-bottom: 213px;"></div></div></div></div></div></div></div></p>
					</div><!-- .entry-content -->







				</article><!-- #post-## -->

			</div>


		</div>


	</div>
</div>



</div><!-- #main-content -->

<!-- Your Google Analytics Plugin is missing the tracking ID -->

		</div><!-- #main -->
  </div>
</template>

<script>
  import {rollup} from "@/lib/utils.js"
export default {

name : 'Projects_tourizm',
mounted() {

  rollup()
}


}


</script>
